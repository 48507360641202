<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<ng-container *ngIf="!isPasswordSent; else success">
  <!-- <strong mat-dialog-title>
    {{ 'nav.forgotyourpassword' | translate }}
  </strong> -->

  <app-forgot-password-form (submitted)="submitted($event)">
    <mzima-client-button
      btn-cancel
      fill="outline"
      color="secondary"
      [mat-dialog-close]="false"
      [data-qa]="'btn-cancel'"
      *ngIf="isDesktop"
    >
      {{ 'app.cancel' | translate }}
    </mzima-client-button>
  </app-forgot-password-form>
</ng-container>

<ng-template #success>
  <div class="success-msg">
    <app-lottie-animation class="visual" [path]="'/assets/lottie/success-animation.json'">
    </app-lottie-animation>
    <strong mat-dialog-title>{{ 'user.check_email' | translate }}</strong>
    <p>{{ 'user.passwordreset_confirm_instructions' | translate }}</p>
    <mzima-client-button mat-dialog-close [mat-dialog-close]="true" [expand]="false">
      {{ 'modal.button.close' | translate }}
    </mzima-client-button>
  </div>
</ng-template>
