<form novalidate [formGroup]="form" class="auth-form" (ngSubmit)="login()" data-qa="form">
  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.email' | translate }}</mat-label>
      <input matInput formControlName="email" required [data-qa]="'email'" />
      <mat-error [data-qa]="'invalid-email-error'" *ngIf="form.get('email')?.invalid">{{
        getErrorMessage('email')
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="form-row">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'user.password' | translate }}</mat-label>
      <input
        matInput
        formControlName="password"
        required
        [type]="isPasswordVisible ? 'text' : 'password'"
        [data-qa]="'password'"
      />
      <mat-error [data-qa]="'invalid-password-error'" *ngIf="form.get('password')?.invalid">{{
        getErrorMessage('password')
      }}</mat-error>
      <mzima-client-button
        matSuffix
        fill="clear"
        color="secondary"
        [iconOnly]="true"
        (buttonClick)="togglePasswordVisible()"
        [data-qa]="'toggle-password'"
      >
        <mat-icon icon [svgIcon]="isPasswordVisible ? 'eye-open' : 'eye'"></mat-icon>
      </mzima-client-button>
    </mat-form-field>
    <mat-error [data-qa]="'invalid-credential-error'">{{ loginError }}</mat-error>
  </div>

  <div class="form-row controls">
    <!-- <mzima-client-button
      (buttonClick)="restorePassword()"
      fill="clear"
      [data-qa]="'restorePassword'"
    >
      {{ 'nav.forgotyourpassword' | translate }}
    </mzima-client-button> -->
  </div>

  <div mat-dialog-actions align="end">
    <ng-content select="[btn-cancel]"></ng-content>

    <mzima-client-button
      type="submit"
      [disabled]="form.invalid || form.disabled || submitted"
      [data-qa]="'btn-login'"
      id="btn-login"
    >
      {{ 'nav.login' | translate }}
    </mzima-client-button>
  </div>
</form>
