<section class="share-modal" [data-qa]="'share-modal'">
  <mzima-client-button
    tabindex="-1"
    fill="outline"
    [iconOnly]="true"
    color="light-gray"
    [data-qa]="'btn-close'"
    class="modal__close-btn"
    [mat-dialog-close]="false"
    ariaLabel="{{ 'modal.button.close' | translate }}"
  >
    <mat-icon icon svgIcon="close"></mat-icon>
  </mzima-client-button>

  <strong mat-dialog-title>{{ label }}</strong>

  <div mat-dialog-content>
    <div class="form-row">
      <mat-label>{{ 'share.web_address' | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          [(ngModel)]="address"
          (input)="changeAddress($event)"
          [data-qa]="'survey-web-address'"
        />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-label>{{ 'share.embed_on_websites' | translate }}</mat-label>
      <div class="copy-success" *ngIf="copySuccess">{{ 'share.copied' | translate }}</div>
      <div [ngClass]="{ success: copySuccess }">
        <mat-form-field appearance="outline">
          <textarea
            class="html-embed"
            matInput
            disabled
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="6"
            [(ngModel)]="htmlEmbed"
          >
          </textarea>
          <mat-icon
            (click)="copyToClipboard()"
            class="fab-button__icon copy"
            svgIcon="copy"
          ></mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons">
      <!-- <mzima-client-button
        fill="outline"
        color="secondary"
        [autoSetMeta]="true"
        [title]="title ? title : ''"
        [description]="description"
        shareButton="twitter"
        [url]="address"
        [data-qa]="'share-twitter'"
      >
        {{ 'share.share_on_twitter' | translate }}
        <mat-icon icon class="fab-button__icon twitter-icon" svgIcon="twitter-fill"></mat-icon>
      </mzima-client-button> -->
      <!-- <mzima-client-button
        fill="outline"
        color="secondary"
        [autoSetMeta]="true"
        [title]="title ? title : ''"
        [description]="description"
        shareButton="facebook"
        [url]="address"
        [data-qa]="'share-facebook'"
      >
        {{ 'share.share_on_facebook' | translate }}
        <mat-icon icon class="fab-button__icon facebook-icon" svgIcon="facebook-fill"></mat-icon>
      </mzima-client-button> -->
    </div>
  </div>
</section>
